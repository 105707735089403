import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Header from "../components/header2"
import Mainfooter from "../components/mainfooter"

const AllCategories = ({
  data: { allMarkdownRemark: { edges } = {} } = {},
}) => {
  React.useEffect(() => {
    console.log("edges", edges)
  }, [edges])

  return (
    <>
      <Header />
      <Seo title="All Services" />

      <div
        className="container mt-5 pt-5 d-flex justify-content-around flex-wrap"
        style={{ minHeight: "50vh" }}
      >
        {edges.map(
          ({
            node: {
              id,
              frontmatter: { categoryName, path, featuredImage },
            },
          }) => (
            <Link
              className="card my-3 pointer "
              key={id}
              to={path}
              style={{ height: "fit-content" }}
            >
              <div className="d-flex justify-content-center">
                <div className="card h-100 shadow-lg">
                  <GatsbyImage image={getImage(featuredImage)} />
                  <div className="card-body">
                    <Link to={path} className="anchorlink mb-4">
                      <h5 className="card-title">{categoryName}</h5>
                    </Link>
                  </div>
                </div>
              </div>
            </Link>
          )
        )}
      </div>

      <Mainfooter />
    </>
  )
}

export default AllCategories

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___sortKey] }) {
      edges {
        node {
          id
          frontmatter {
            title
            articleHeading
            categoryID
            categoryName
            path
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  quality: 90
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`
